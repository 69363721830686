import { useAsync } from "@app/utils/react-async-hook";
import { createContext, useMemo } from "react";
import * as API from "@app/API";
import { hasDataError, isDataLoading } from "@app/redux/utils";
import Spinner from "@app/toolkit/Spinner";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserContext } from "@app/_Login/actions";
import { getLoginRedirectUrl } from "./utils";
import { setPreferences } from "@app/redux/data/preferences";

export interface AuthContextValue {
  isAuthenticated: boolean;
  userProfile: any;
  preferences: any;
  integrations: any[];
  refresh: () => Promise<any>;
}

const defaultContextValue: AuthContextValue = Object.freeze({
  isAuthenticated: false,
  userProfile: null,
  preferences: null,
  integrations: [],
  refresh: () => Promise.resolve(null),
});

export const useIdentity = () => {
  const dispatch = useDispatch();
  const { status, result, execute } = useAsync<AuthContextValue>(
    API.getIdentity,
    []
  );

  if (result?.userProfile) {
    dispatch(setUserContext(result.userProfile || null));
  }

  if (result?.preferences) {
    dispatch(setPreferences(result.preferences));
  }

  return useMemo(
    () => ({ identity: result, identityStatus: status, refresh: execute }),
    [result, status, execute]
  );
};

export const AuthContext = createContext<AuthContextValue>(defaultContextValue);

function AuthProvider({ children }) {
  const {
    identity = defaultContextValue,
    identityStatus,
    refresh,
  } = useIdentity();

  const myProfile = useMemo(() => {
    return {
      ...identity,
      refresh,
    };
  }, [refresh, identity]);

  if (isDataLoading(identityStatus)) return <Spinner modal />;

  if (hasDataError(identityStatus)) return <Redirect to={"/unauthorized"} />;

  if (!identity.isAuthenticated) {
    window.location.href = getLoginRedirectUrl();
    return null;
  }

  return (
    <AuthContext.Provider value={myProfile}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
